import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
declare var swal: any;
import { PlatformLocation, LocationStrategy } from '@angular/common'
import { constant } from '../constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  url: string = constant.url;  
  routeParams: any;
  userId: string =  null;

  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization' : localStorage.getItem('authorization')
      }) 
  };
  constructor(public router: Router, private activeRoute: ActivatedRoute, private zone: NgZone, private location: PlatformLocation, private locationStrategy: LocationStrategy,private _http: HttpClient, private toastr: ToastrService) { 
    //if user comes to page from credit-card page, check if lifeResetSubscription is not present in localstorage and redirect to credit-card screen
    // if(!localStorage.getItem('lifeResetSubscription')) {
    //     window.history.go(-1);
    // }
    if (localStorage.getItem('authorization')) { 

      // determine if the user is logged in from this method, if not, redirect to previous screen
      // localStorage.setItem('authorization', null);
      history.pushState(null, null, location.href);
      this.locationStrategy.onPopState(() => {
        history.pushState(null, null, location.href);
      });
    }
    
      //if user not authorized, redirect to login page
      if (!localStorage.getItem('authorization')) {
  
          this.router.navigate(['/login']);
      }

      if(!localStorage.getItem('lifeResetSubscription')) {
        this.routeParams = this.activeRoute.snapshot.params;
        this.userId = this.routeParams.userId;
         this.router.navigate(['/credit-card', this.userId]);
      }
    }
  
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

      //if user doesnt have subscription or going to credit-card screen ,redirect to welcome screen 
      let url = "/credit-card" + "/" + this.userId;
      if(this.router.url == url, this.userId) {
        this.router.navigate(['/welcome', this.userId]);
          return;
      }
      // if user enters login in window link , but is authorized, stop from redirection, else redirect to login
      // if(this.router.url == '/login' && localStorage.getItem('authorization') != null) {
      //     return false;
      // } else {
      //     return true;
      // }
    }

  ngOnInit() {
  }

  //logout
  logout() {
    swal({
      title: 'Er du sikker på at du vil logge ut?',
      type: "info",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja',
      cancelButtonText: "Nei", 
    }).then(()=> {
      localStorage.removeItem("tempVar");
      localStorage.removeItem('email');
      localStorage.removeItem('authorization');
      localStorage.removeItem('lifeResetSubscription');

    let logoutUrl = this.url + 'user/logout';
    this._http.post<boolean>(logoutUrl, this.options).subscribe(x => {
    },
      err => {
        this.toastr.error(err.error.message, 'Error');
      }
    );
      this.router.navigate(['/login']);
    });
  }

}
