import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
// import { ISubscription } from "rxjs/Subscription";
import { ToastrService } from 'ngx-toastr';
import { VerificationService } from '../verification/verification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  subscription: any;

    constructor(private _service: LoginService, private router: Router, private toastr: ToastrService, private _verificationService: VerificationService) {

      if (localStorage.getItem('authorization') && localStorage.getItem('authorization') != null) { 
        
        // determine if the user is logged in from this method , redirect back to previous screen.
          window.history.go(-1);
      }
  
      localStorage.setItem('tempVar', '2');
    }

  email: string;
  pwd: string;
  userId: string = null;
  public loading = false;
  validate: boolean = false;
  // private subscription: ISubscription;

  ngOnInit() {
    
    this.validate = false;
  }

  //Log In for existing user
  logIn(){
    this.loading = true;
    localStorage.setItem('email', this.email);

      let data : any = {
          "email": this.email.trim(),
          "password": this.pwd.trim()
      }
      
      // this.subscription = this._service.logIn(data).subscribe(x => {
        this.subscription = this._service.logIn(data).subscribe(x => {

        this.email = null;
        this.pwd = null;
        this.userId = x.data.id;
        localStorage.setItem('authorization', x.token);
        this.toastr.success(x.message,'Success');

        setTimeout(()=> {
            //redirect to stores page if user has membership, otherwise to membership page
            if(x.data.isLifeResetSubscription == true) {
              this.loading = false;
              localStorage.setItem('lifeResetSubscription', 'true');
              this.router.navigate(['/welcome', this.userId]);
            } else {
              this.loading = false;
              this.router.navigate(['/credit-card', this.userId]);
            }
          }, 2000);
      },
        err => {
          
          //if user is not verified redirect to verification page for verifying new OTP sent on email Id now.
          if(err.error.message == "User not verified") {

            let data: any = {
              "email": localStorage.getItem('email')
            }
            // this._verificationService.resendOtp(data).subscribe(x => {

            //   this.toastr.error('Brukeren er ikke bekreftet. Vennligst sjekk e-post-id for å bekrefte OTP','Beskjed');
            //   setTimeout(()=> {
            this.loading = false;
            this.router.navigate(['/verification']);
            //   }, 2000);
            // },
            //   err => {
                // this.loading = false;
            //       this.toastr.error('Error', err.error.message);
            //   }
            // );
          } else {
            this.toastr.error(err.error.message, 'Error');
            this.loading = false;
          }
        }
      );
  }

  //remove space 
  removeSpace(event) {

      var k = event ? event.which : event.keyCode;
      if (k == 32) return false;
  }

  // free the memory unsubscribing subcription instances
  ngOnDestroy() {
    if(this.subscription) {

        this.subscription.unsubscribe();
    }
  }

}