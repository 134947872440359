import { Component, OnInit } from '@angular/core';
// import { ISubscription } from "rxjs/Subscription";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VerificationService } from './verification.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {
  userId: number;
 
  constructor(private _service: VerificationService, private router: Router, private toastr: ToastrService) { 
    // determine if the user is logged in from this method , redirect back to previous screen.
    if (localStorage.getItem('authorization') && localStorage.getItem('authorization') != null) { 
    
        window.history.go(-1);
    }
    if (!localStorage.getItem('email')) { 
    
      this.router.navigate(['/login']);
    }
    //set temp variable if not, for re-initializing datepicker on sign-up page
    localStorage.setItem('tempVar', '2');
  }
  
  loading: boolean = false;
  email: string;
  otp: any;
  validate: boolean = false;

  ngOnInit() {
  }

  // verify OTP of user and redirect on result
  verifyOtp() {
    let email: string = localStorage.getItem('email');
    if(!email) {
        this.router.navigate(['/login']);
    }
    this.loading = true;
    let data : any = {
      "email": localStorage.getItem('email'),
      "otp": this.otp
    }
    // this.subscription = this._service.logIn(data).subscribe(x => {
    this._service.verifyOtp(data).subscribe(x => {

      let serverResponce:any = x;
      localStorage.setItem('authorization', x.token);
      this.toastr.success(x.message, 'Success');
      this.loading = false;
      this.router.navigate(['/credit-card', x.data.id]);
    },
      err => {
        console.log('error------', );
        if(err.status == 403) {
          this.toastr.error(err.error.message, 'Error');
        } else {
          this.toastr.error(err.error.message, 'Error');
        }
        this.loading = false;
      }
    );
  }

  //remove space 
  removeSpace(event) {
  
      var k = event ? event.which : event.keyCode;
      if (k == 32) return false;
  }

  //remove charachters or special charachters
  removeChars(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57)) { 
      return;
  } else if(typeof(event.key) == 'string') {
      event.preventDefault();
  } else {
      event.preventDefault();
  } 
    //Disable charachter inputs in datepicker input field
      if((event.code.search('Digit') != 0 ||
        event.key == '_' || event.key == '!' || event.key == '@' || event.key == '#' || event.key == '$' || event.key == '%' || event.key == '^' || event.key == '&' || event.key == '*' || event.key == '(' || event.key == ')' || event.key == '+' || event.key == '=' || event.key == '\\' || event.key == '|' || event.key == ';' || event.key == ':' || event.key == "'" || event.key == '"' || event.key == ',' || event.key == '<' || event.key == '>' || event.key == '/' || event.key == '?' || event.key == '`' || event.key == '~'  || event.key == '['  || event.key == ']'  || event.key == '{'  || event.key == '}' || event.key == "-" || event.key == "." || event.key == "e") && !(event.keyCode < 96 || event.keyCode > 105) || event.key == "e" || event.charCode == 43 || event.charCode == 45 || event.charCode == 46 || event.charCode == 69 && event.which != 8 && event.which != 0 ) {
            event.preventDefault();
      }
  };

  //resend OTP 
  resendOtp() {
    this.loading = true;

      let data : any = {
        "email": localStorage.getItem('email'),
      }
      this._service.resendOtp(data).subscribe(x => {

        let serverResponce:any = x;
        this.toastr.success(x.message, 'Success');
        this.loading = false;
      },
        err => {

          if(!localStorage.getItem('email')) {
            this.toastr.error('Registrer deg først', 'Error');
          } else {
            this.toastr.error(err.error.message, 'Error');
          }
          this.loading = false;
        }
      );
  }
}
