import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
// import { ISubscription } from "rxjs/Subscription";

import { SignUpService } from './sign-up.service';
import { ToastrService } from 'ngx-toastr';

import * as $ from 'jquery';
declare var swal: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  loading: boolean = false;

  constructor(private service: SignUpService,  public router: Router, private toastr: ToastrService) { 

    // determine if the user is logged in from this method, else redirect to previous screen
  if (localStorage.getItem('authorization')) { 
      
      window.history.go(-1);
  }
}

name: string;
gender: any = 0;
year: any;
currentYear: any;
email: string;
pwd: string;
confirmPwd: string;
model: any;
userRoles: any[] =[];
userRole: number = 0;
validate: boolean = false;
orgNo: any = "";
phoneNo: any = "";
type: string = 'user';
billAddr: string = "";
orgName: string;
// private subscription: ISubscription;

  ngOnInit() {
    // refresh window if coming to current page second time after visiting once for re-initializing date-picker
    if(!localStorage.getItem('tempVar')) {
      localStorage.setItem('tempVar', '2');
    } else {
      localStorage.removeItem('tempVar');
      location.reload();
    }

    this.validate = false;
    this.currentYear = this.year;
    this.getUserRoles();

    // this.navigationSubscription = this.router.events.subscribe((e: any) => {
     this.router.events.subscribe((e: any) => {
       
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  initialiseInvites() {
    // Set default values and re-fetch any data you need.
  }

  removeChars(event) {
      if ((event.keyCode >= 48 && event.keyCode <= 57)) { 
        return;
    } else if(typeof(event.key) == 'string') {
        event.preventDefault();
    } else {
        event.preventDefault();
    } 
    //Disable charachter inputs in datepicker input field
      if((event.code.search('Digit') != 0 ||
        event.key == '_' || event.key == '!' || event.key == '@' || event.key == '#' || event.key == '$' || event.key == '%' || event.key == '^' || event.key == '&' || event.key == '*' || event.key == '(' || event.key == ')' || event.key == '+' || event.key == '=' || event.key == '\\' || event.key == '|' || event.key == ';' || event.key == ':' || event.key == "'" || event.key == '"' || event.key == ',' || event.key == '<' || event.key == '>' || event.key == '/' || event.key == '?' || event.key == '`' || event.key == '~'  || event.key == '['  || event.key == ']'  || event.key == '{'  || event.key == '}' || event.key == "-" || event.key == "." || event.key == "e") && !(event.keyCode < 96 || event.keyCode > 105) || event.key == "e" || event.charCode == 43 || event.charCode == 45 || event.charCode == 46 || event.charCode == 69 && event.which != 8 && event.which != 0 ) {
            event.preventDefault();
      }
  };
  
  //Sign Up for new user
  signUp(){
    this.loading = true;
    let tempYear = $('#txtYear').val() 

    if(!tempYear) {
      tempYear = 0;
    }

    if(this.gender == 0) {
      this.gender = "";
    }

    // if(this.orgNo) {
    //   this.orgNo = Number(this.orgNo);
    // }

    localStorage.setItem('email', this.email.trim());

    if(this.phoneNo) {
      this.phoneNo = this.phoneNo.toString();
    }

    let name: any;
    if(this.name) {
        name= this.name;
    }

    if(typeof(this.orgNo) != 'string') {
        this.orgNo = this.orgNo.trim()
    } 
     if(this.orgNo) {
        this.orgNo = this.orgNo.trim()
    }

    if(this.orgName) {
        name = this.orgName;
    }

    if(this.billAddr) {
      this.billAddr = this.billAddr.trim()
    }
    let data : any = {
        "name": name.trim(),
        "gender": this.gender,
        "year_of_birth": Number(tempYear),
        "email": this.email.trim(),
        "password": this.pwd.trim(),
        "account_id": 0,
        "role_id": Number(this.userRole),
        'phone_number': this.phoneNo,
        'organization_number' : this.orgNo,
        "billing_address": this.billAddr,
        "is_life_reset": true
    }

    // this.subscription = this.service.signUp(data).subscribe(x => {
    this.service.signUp(data).subscribe(x => {
        this.billAddr = null;
        // this.toastr.success('En link for å bekrefte kontoen din er sendt til din epostadresse', 'Success',);

        //after successful signup check security code in email Id and redirect page to login
        swal({
          title:  "En link for å bekrefte kontoen din er sendt til din e-postadresse",
          type: "success",
          showLoaderOnConfirm: true,
          confirmButtonColor: '#3085d6',
        })

        this.name = null;
        this.orgName = null;
        this.email = null;
        this.pwd = null;
        this.confirmPwd = null;
        this.billAddr = "";
        this.orgNo = "";
        this.phoneNo = "";
        this.gender = 0;
        this.userRole = 0;
        this.loading = false;
        setTimeout(()=> {
          this.router.navigate(['/verification']);
        },2000);
    },
      err => {
          this.billAddr = null;
          this.name = null;
          this.billAddr = "";
          this.orgNo = "";
          this.phoneNo = "";
          if(err.status == 409) {
            this.toastr.error(err.error.message, 'Message');
          } else {
            this.toastr.error('Error', err.error.message);
          }
          this.gender = 0;
          this.userRole = 0;

          setTimeout(function() {
            // location.reload();
          }, 2000);
          this.loading = false;
        }
    );
  }

  //remove space 
  removeSpace(event) {

        var k = event ? event.which : event.keyCode;
        if (k == 32) return false;
  }

  //get user roles
  getUserRoles() {

    this.service.getUserRoles().subscribe(x => {
        this.userRoles = x.data.rolesList;
    },
      err => {

          this.toastr.error('Error', err.error.message);
        }
    );
  }

  //navigate to terms and conditions page 
  navigateToTerms() {

    this.router.navigate([]).then(result => { 
        window.open('/terms-conditions', '_blank')
    });; 
   }

   // free the memory unsubscribing subcription instances
   ngOnDestroy() {

    // if(this.subscription) {

    //     this.subscription.unsubscribe();
    // }    
  // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    // if (this.navigationSubscription) {  
    //    this.navigationSubscription.unsubscribe();
    // }
  }
}
