import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'madetogrow';
  
  ngOnInit() {
      //display on internet explorer
      if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(suffix) {
        return this.indexOf(suffix, this.length - suffix.length) !== -1;
      }
    }
  }
}
