import { Injectable } from '@angular/core';
import { Observable, observable, Observer } from 'rxjs';
import { constant } from '../constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  url: string = constant.url;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization' : localStorage.getItem('authorization')
      }) 
  };

  constructor(private _http: HttpClient) { }

  verifyOtp(data) : Observable<any> {
  

    let options = { 'headers': new HttpHeaders({ 'Content-Type': 'application/json',
    }) 
    }
    let subscribeUrl: string = this.url + 'user/life-reset/verify-otp'
    return this._http.post<boolean>(subscribeUrl,data, options);
  }

  resendOtp(data) : Observable<any> {
  
  
      let options = { 'headers': new HttpHeaders({ 'Content-Type': 'application/json',
      }) 
      }
      let subscribeUrl: string = this.url + 'user/life-reset/resend-otp'
      return this._http.post<boolean>(subscribeUrl,data, options);
    }
}