import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { VerificationComponent } from './verification/verification.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
	{ path: '', component: LoginComponent },
    { path:  'login',  component:  LoginComponent},
    { path:  'sign-up',  component:  SignUpComponent},
    { path:  'credit-card/:userId',  component:  CreditCardComponent},
    { path:  'terms-conditions',  component:  TermsConditionsComponent},
    { path:  'verification',  component:  VerificationComponent},
    { path:  'welcome/:userId',  component:  WelcomeComponent},
    { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
