import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { constant } from '../constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    url: string = constant.url;
    options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private _http: HttpClient) { }
    
    logIn(data): Observable<any> {
        let logInUrl: string = this.url + 'user/v1/sign-in';
        return this._http.post<boolean>(logInUrl, data, this.options);
    }
}
