import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { VerificationComponent } from './verification/verification.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { NgxLoadingModule } from 'ngx-loading';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { constant } from './constant';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    CreditCardComponent,
    TermsConditionsComponent,
    VerificationComponent,
    WelcomeComponent
  ],
  imports: [
    BrowserModule,  
    BrowserAnimationsModule, 
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxStripeModule.forRoot(constant.stripKey),
    NgxLoadingModule.forRoot({}),
    ToastrModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
