import { Injectable } from '@angular/core';
import { Observable, observable, Observer } from 'rxjs';
import { constant } from '../constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {

  url: string = constant.url;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization' : localStorage.getItem('authorization')
      }) 
  };

  constructor(private _http: HttpClient) { }

  //subscribe
  subscribe(data) : Observable<any> {
      let authToken = localStorage.getItem('authorization')

      let option = { 'headers': new HttpHeaders({ 'Content-Type': 'application/json'
    }) 
      }
    let subscribeUrl: string = this.url + 'user/life-reset/subscribe-plan';
    return this._http.post<boolean>(subscribeUrl,data, option);
  }

  logout()  : Observable<any> {

    let options = { 'headers': new HttpHeaders({ 'Content-Type': 'application/json'}) 
    }
    let logoutUrl: string = this.url + 'user/logout';
    return this._http.post<boolean>(logoutUrl, options);
  }

}